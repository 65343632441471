import Image from "next/image";

export default function Features() {
  return (
    <div className="section-gap"> {/* Reduced from section-gap */}
      {/* Background Decoration */}
      <div className="absolute inset-0 bg-gradient-to-br from-[#B5E4CA]/40 to-[#B5E4CA]/10" />

      <div className="relative z-10 max-w-8xl mx-auto px-4">
        {/* Main Heading */}
        <h2 className="flex flex-col items-center mb-8 font-bold text-primary tracking-tight text-center">
          {/* <span className="text-2xl md:text-3xl">With Advanced</span> */}
          <span className="text-3xl md:text-4xl lg:text-4xl block mt-1">With Pre-built FREE Features</span>
        </h2>

        {/* Center Image */}
        <div className="relative max-w-xl mx-auto flex items-center justify-center"> {/* Reduced max-width */}
          <Image
            src="/advanced_features.svg"
            alt="Features illustration"
            className="w-[400px] h-auto"
            width={400}
            height={400}
          />
        </div>
      </div>
    </div>
  );
}
