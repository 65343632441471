"use client";
import { useState } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { motion, AnimatePresence } from "framer-motion";

const fetchFAQS = async () => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}/admin/faq?limit=10&page=1`
  );
  const data = response.data;

  const sanitizedData = data.result.docs.map((faq) => ({
    question: faq.question,
    answer: faq.answer,
  }));

  return sanitizedData;
};

export default function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const { isLoading, error, data: allFaqs } = useQuery({
    queryKey: ["FAQS"],
    queryFn: fetchFAQS,
  });

  // Calculate visible FAQs based on showAll state
  const visibleFaqs = showAll ? allFaqs : allFaqs?.slice(0, 5);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleViewAll = () => {
    setShowAll(!showAll);
    setActiveIndex(null); // Reset active index when toggling view
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" }
    }
  };

  // Skeleton loader for FAQs
  const skeletonLoader = (
    <div className="space-y-4">
      {[...Array(5)].map((_, index) => (
        <div key={index} className="animate-pulse flex flex-col space-y-2">
          <div className="h-4 bg-[#004B20] rounded w-3/4"></div>
          <div className="h-4 bg-[#004B20] rounded w-2/3"></div>
        </div>
      ))}
    </div>
  );

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: "-100px" }}
      variants={containerVariants}
      id="faqs"
      className="section-gap"
    >
      {/* Header Section */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="flex flex-col items-center mb-8"
      >
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-primary 
          tracking-tight text-center relative">
          FAQs
        </h2>
      </motion.div>

      {/* FAQ Content */}
      <motion.div variants={containerVariants} className="max-w-3xl mx-auto">
        {isLoading ? (
          <motion.div variants={itemVariants}>{skeletonLoader}</motion.div>
        ) : error ? (
          <motion.div variants={itemVariants} className="text-center text-red-500">
            {error.message}
          </motion.div>
        ) : visibleFaqs?.length > 0 ? (
          <AnimatePresence>
            {visibleFaqs.map((faq, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className="mb-4"
              >
                <motion.div
                  className="bg-white/80 backdrop-blur-sm rounded-xl p-4 md:p-6
                    hover:shadow-lg transition-all duration-300 cursor-pointer
                    border border-primary/10"
                  onClick={() => toggleFAQ(index)}
                  whileHover={{ scale: 1.01 }}
                >
                  {/* Question */}
                  <div className="flex justify-between items-start sm:items-center gap-3 sm:gap-4">
                    <h3 className="text-base sm:text-lg md:text-xl font-medium text-primary 
                      leading-tight sm:leading-normal flex-1 pr-2">
                      {faq.question}
                    </h3>
                    <motion.div
                      initial={false}
                      animate={{ rotate: activeIndex === index ? 180 : 0 }}
                      transition={{ duration: 0.3 }}
                      className="flex-shrink-0 mt-1 sm:mt-0"
                    >
                      <div className="w-6 h-6 sm:w-8 sm:h-8 bg-primary/10 rounded-full 
                        flex items-center justify-center">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-primary transform transition-transform duration-300
                            sm:w-4 sm:h-4"
                        >
                          <path
                            d="M7 1V13"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            className={`transform origin-center transition-transform duration-300 
                              ${activeIndex === index ? 'scale-y-0' : 'scale-y-100'}`}
                          />
                          <path
                            d="M1 7H13"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                    </motion.div>
                  </div>

                  {/* Answer */}
                  <AnimatePresence>
                    {activeIndex === index && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="overflow-hidden"
                      >
                        <p className="text-sm sm:text-base text-gray-600 mt-3 sm:mt-4 
                          leading-relaxed">
                          {faq.answer}
                        </p>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              </motion.div>
            ))}
          </AnimatePresence>
        ) : (
          <motion.div variants={itemVariants} className="text-center text-gray-500">
            No FAQs available.
          </motion.div>
        )}

        {/* View All Button - Only show if there are more than 5 FAQs */}
        {allFaqs?.length > 5 && (
          <motion.div
            variants={itemVariants}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mt-8"
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={toggleViewAll}
              className="bg-primary text-white px-8 py-3 rounded-full
                font-medium hover:shadow-lg transition-all duration-300
                border border-primary hover:bg-primary/90"
            >
              <AnimatePresence mode="wait">
                <motion.span
                  key={showAll ? 'less' : 'more'}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className="relative z-10 inline-block"
                >
                  {showAll ? "View Less" : "View All"}
                </motion.span>
              </AnimatePresence>
            </motion.button>
          </motion.div>
        )}
      </motion.div>
    </motion.div>
  );
}
