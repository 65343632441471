"use client";
import { useEffect } from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";

import Banner from "../components/common/Banner";
import Blogs from "../components/common/Blogs";
import Footer from "../components/common/Footer";
import FAQ from "../components/MainPage/FAQ";
import dynamic from "next/dynamic";
import HowItWorks from "../components/MainPage/HowItWorks";
import OurPlans from "../components/MainPage/OurPlans";
import Testimonials from "../components/MainPage/Testimonials";
import Features from "../components/common/Features";
import { BANNER_ONE, BANNER_TWO } from "../constants/data";

// Replace static import with dynamic
const Hero = dynamic(() => import("../components/MainPage/Hero"), {
  loading: () => (
    <div className="animate-pulse bg-secondary h-[600px] rounded-[30px]" />
  ),
  ssr: true,
});

export default function Home() {
  // Scroll to section handling
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const section = urlParams.get("section");
    if (section) {
      const navHeight = 100; // 20px padding-top from PageWrapper
      const elementPosition = document
        .getElementById(section)
        .getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div
      id="home"
      className="bg-gradient-to-b from-white to-primary50/20 min-h-screen"
    >
      {/* Hero Section */}
      <div className="relative px-5 md:px-20">
        {/* Main Content Container */}
        <section className="mt-4 md:mt-2 max-w-[1920px] mx-auto">
          <Hero />
        </section>
        <div className="space-y-16 md:space-y-[90px] max-w-[1920px] mx-auto relative z-10">
          {/* How It Works Section */}
          <section>
            <HowItWorks />
          </section>

          {/* Our Plans Section */}
          <section>
            <OurPlans />
          </section>

          {/* Demo Section */}
          <section>
            <Features />
          </section>

          {/* Testimonials Section */}
          <section>
            <Testimonials />
          </section>
          {/* Blogs Section */}
          <section>
            <Blogs />
          </section>

          {/* Banners and Blogs Section */}
          <section>
            <Banner
              heading={BANNER_ONE.heading}
              description={BANNER_ONE.description}
              button
              breakTitle={true}
            />
          </section>
          {/* FAQ Section */}
          <section>
            <FAQ />
          </section>
          {/* Play Store and App Store Download Section */}
          <section className="mb-5">
            <Banner
              heading={BANNER_TWO.heading}
              description={BANNER_TWO.description}
            />
          </section>
        </div>

        {/* Decorative Background Elements */}
        <div
          className="absolute top-0 right-0 w-1/2 h-1/2 bg-primary100 
          rounded-full blur-[150px] opacity-20 -z-1"
        />
        <div
          className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-primary200 
          rounded-full blur-[150px] opacity-20 -z-1"
        />
      </div>
      <FloatingWhatsApp
        phoneNumber="919625991431" // Replace with your WhatsApp business number
        accountName="main10"
        allowEsc
        // allowClickAway
        notification
        notificationSound
        statusMessage="Typically replies within 1 hour"
        chatMessage="Hello! 👋 How can we help you?"
        avatar="/favicon.svg" // Add your company logo path here
      />
      {/* Footer */}
      <footer className="mt-16 md:mt-[90px]">
        <Footer />
      </footer>
    </div>
  );
}
